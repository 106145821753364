// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from "dayjs";
import Header from "../../components/Header";
import AddRegulations from "./AddRegulations";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";
import GENERIC_CONSTANTS from "../../constants/Regulations/Generic";
import RegulationApi from "../../services/RegulationsApi";
import getApiHeaders from "../../services/utils.js/apiHeaders";
import {
  setAllRegulationList, clearRegulationFormData, updateRegulationFormData, setUpdatedRegulationData,
} from "../../redux/regulationMaintenanceSlice";
import UpdateRegulations from "./UpdateRegulations";
import "./viewRegulations.css";

// eslint-disable-next-line react/prop-types
const ViewRegulations = ({ isPingSsoEnabled = false }) => {
  const dispatch = useDispatch();
  const {
    regulationList, newRegulationData, regulationFormData, updatedRegulationData,
  } = useSelector(
    (state) => state.regulationMaintenanceForm,
  );
  const { userRoles, authxToken } = isPingSsoEnabled
    ? useSelector((state) => state.loginToken)
    : { userRoles: ["Block_Sale_R"], authxToken: "null" };

  const [selectedState, setSelectedState] = useState("All");
  const [searchString, setSearchString] = useState("");
  const [partsSearchString, setPartsSearchString] = useState("");
  const [fieldLinePartsSearchString, setFieldLinePartsSearchString] = useState("");
  const [regulationsData, setRegulationsData] = useState([]);
  const [filteredRegulationsData, setFilteredRegulationsData] = useState([]);
  const [regStateOptions, setRegStateOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("regTabOne");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRegId, setSelectedRegId] = useState("");
  const [selectedRegulation, setSelectedRegulation] = useState(null);
  const [selectedRegulationFilter, setSelectedRegulationFilter] = useState({});
  const [regulationApiError, setRegulationApiError] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showApiSuccess, setShowApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [isDataLossAlert, setIsDataLossAlert] = useState(false);
  const [isUpdateRegulation, setIsUpdateRegulation] = useState(false);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [selectedRegulationIds, setSelectedRegulationIds] = useState("");

  const getFormattedDate = (dateVal) => {
    if (dayjs && typeof dayjs === "function") {
      return dayjs(dateVal).format("YYYY-MM-DD");
    }
    return dateVal;
  };

  const getTodayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    today = `${yyyy}-${mm}-${dd}`;
    return today;
  };

  const getFormattedActiveRegulation = (activeRegulation) => {
    let formattedActiveReg = activeRegulation;
    formattedActiveReg = {
      ...formattedActiveReg,
      effDate: formattedActiveReg.effDate == null ? "-" : getFormattedDate(formattedActiveReg.effDate),
      createdDate: formattedActiveReg.createdDate == null ? "-" : getFormattedDate(formattedActiveReg.createdDate),
      modifiedDate: formattedActiveReg.modifiedDate == null ? "-" : getFormattedDate(formattedActiveReg.modifiedDate),
    };
    formattedActiveReg.parts = formattedActiveReg.parts.map((each) => ({
      ...each,
      createdDate: each.createdDate == null ? "-" : getFormattedDate(each.createdDate),
      createdBy: each.createdBy,
      modifiedDate: each.modifiedDate == null ? "-" : getFormattedDate(each.modifiedDate),
      modifiedBy: each.modifiedBy,
      title: each.partDesc
        ? each.partDesc.replace(/^-/, "")
        : `${each.lineAbbrev}-${each.partNo}`,
    }));
    return formattedActiveReg;
  };

  const handleAlertClose = () => {
    setShowApiSuccess(false);
    setApiSuccessMessage("");
  };

  const handleEditModalClose = () => {
    setIsUpdateRegulation(false);
  };

  const handleEditRegulation = (selectedRegulationId) => {
    handleAlertClose();
    const regulationToUpdate = regulationsData.filter(
      (item) => item.regId === selectedRegulationId,
    );
    setSelectedRegulationIds(selectedRegulationId);
    dispatch(updateRegulationFormData({ value: regulationToUpdate[0] }));
    setIsUpdateRegulation(true);
  };

  const handleDialogOpen = (isOpen) => {
    setIsDataLossAlert(isOpen);
  };

  const handleDataDiscard = () => {
    dispatch(clearRegulationFormData());
    handleDialogOpen(false);
    setSelectedTab("regTabOne");
  };

  const handleTabChange = (event, newValue) => {
    if (regulationFormData && regulationFormData.regTitle) {
      handleDialogOpen(true);
    } else {
      setSelectedTab(newValue);
      handleAlertClose();
    }
  };

  const stateFilter = (regData, selectedStateFilter) => {
    let filteredStateRegulations;
    if (selectedStateFilter === "All") {
      filteredStateRegulations = regData;
    } else {
      filteredStateRegulations = regData.filter(
        (eachItem) => eachItem.stateCode === selectedStateFilter,
      );
    }
    return filteredStateRegulations;
  };

  const searchFilter = (regData, searchKey) => {
    const regexKey = /^[a-zA-Z0-9-]*$/;
    const allowed = [
      REGULATIONS_CONSTANTS.REG_TITLE,
      REGULATIONS_CONSTANTS.RULE_NO,
      REGULATIONS_CONSTANTS.REG_JURISDICTION_LEVEL,
      REGULATIONS_CONSTANTS.STATE_CODE,
      REGULATIONS_CONSTANTS.REG_TYPE,
      REGULATIONS_CONSTANTS.EFF_DATE,
      REGULATIONS_CONSTANTS.PARTS,
    ];
    if (regexKey.test(searchKey)) {
      const regex = new RegExp(searchKey, "i");
      return regData.filter((item) => Object.entries(item).find((value) => {
        if (value[0] === REGULATIONS_CONSTANTS.PARTS) {
          return regex.test(value[1].length);
        }
        return allowed.includes(value[0]) && regex.test(value[1]);
      }));
    }
    return regData;
  };

  const partFilter = (regData, searchKey) => {
    const regexKey = /^[a-zA-Z0-9-]*$/;
    if (regexKey.test(searchKey)) {
      return regData.filter((item) => Object.entries(item).find((value) => {
        if (value[0] === REGULATIONS_CONSTANTS.PARTS) {
          const filteredRegulationParts = value[1].filter(
            (part) => (`${part.lineAbbrev}${part.partNo}`).toLowerCase()
              .includes(searchKey.toLowerCase().replaceAll(" ", "")),
          );

          if (filteredRegulationParts && filteredRegulationParts.length > 0) {
            return true;
          }
          return false;
        }
        return false;
      }));
    }
    return regData;
  };

  useEffect(() => {
    const nonExipriryPartsdata = [];
    let filtersRegData = regulationsData;
    if (searchString) {
      filtersRegData = searchFilter(filtersRegData, searchString);
    }

    if (selectedState) {
      filtersRegData = stateFilter(filtersRegData, selectedState);
    }

    if (fieldLinePartsSearchString) {
      filtersRegData = partFilter(filtersRegData, fieldLinePartsSearchString);
    }
    filtersRegData?.forEach((rl) => {
      const nonExipiryObj = { ...rl };
      const nonExpiryartParts = [];
      nonExipiryObj?.parts?.forEach((rp) => {
        const currentDate = getTodayDate();
        if (rp?.expiredDate === null || rp?.expiredDate > currentDate) {
          nonExpiryartParts.push(rp);
        }
      });
      if (nonExpiryartParts.length >= 0) {
        nonExipiryObj.parts = nonExpiryartParts;
      }
      nonExipriryPartsdata.push(nonExipiryObj);
    });
    setFilteredRegulationsData(nonExipriryPartsdata);
  }, [searchString, selectedState, fieldLinePartsSearchString]);

  const handleStateSelection = (event) => {
    const selectedStateFilter = event.target.value;
    setSelectedState(selectedStateFilter);
  };

  const handleSearchChange = (event) => {
    const keyword = event.target.value;
    const regex = /^[a-zA-Z0-9- ]*$/;
    if (keyword === "" || regex.test(keyword)) {
      setSearchString(keyword);
    }
  };

  const handleSearchFiledLineParts = (event) => {
    const partKeyword = event.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (regex.test(partKeyword)) {
      setFieldLinePartsSearchString(partKeyword);
    }
  };

  const handleSearchParts = (event) => {
    const nonExpiryartParts = [];
    const activeRegulation = regulationsData.filter(
      (item) => item.regId === selectedRegId,
    );
    const formattedActiveRegulation = getFormattedActiveRegulation(
      activeRegulation[0],
    );
    const regulatedParts = formattedActiveRegulation.parts.map((each) => ({
      ...each,
      obsoleteDate: each.obsoleteDate ? getFormattedDate(each.obsoleteDate) : each.obsoleteDate,
      id: `${each.partNo}-${each.partDesc}-${each.lineAbbrev}`,
    }));
    const partKeyword = event.target.value;
    const regex = /^[a-zA-Z0-9 ]*$/;
    if (partKeyword !== "" || regex.test(partKeyword)) {
      setPartsSearchString(partKeyword);
    } else {
      return;
    }
    const filteredRegulationParts = regulatedParts.filter(
      (item) => (`${item.lineAbbrev}${item.partNo}`).toLowerCase()
        .includes(partKeyword.toLowerCase().replaceAll(" ", ""))
        || item.partDesc.toLowerCase().includes(partKeyword.toLowerCase()),
    );
    filteredRegulationParts?.forEach((rp) => {
      const currentDate = getTodayDate();
      if (rp?.expiredDate === null || rp?.expiredDate > currentDate) {
        nonExpiryartParts.push(rp);
      }
    });
    setSelectedRegulationFilter(nonExpiryartParts);
  };

  const getAllStateCodes = (regulations) => {
    const uniqueStateCodes = regulations
      .map((eachItem) => eachItem.stateCode)
      .filter(
        (value, index, currentValue) => currentValue.indexOf(value) === index,
      );
    uniqueStateCodes.sort((a, b) => (a > b ? 1 : -1));
    uniqueStateCodes.push("All");
    return uniqueStateCodes;
  };

  const getRegulationsApiData = (regulationCount) => {
    setSpinner(true);
    const headers = getApiHeaders(authxToken.access_token);
    const config = {
      params: {
        page: 0,
        size: regulationCount,
        "sort-field": "createdDate",
        "sort-order": "desc",
      },
      ...headers,
    };

    const apiCallback = (response) => {
      if (response.status === 200) {
        if (
          response
          && response.data
          && response.data.regulationList
          && response.data.regulationList.length > 0
        ) {
          setSpinner(false);
          const apiResponse = response.data.regulationList;
          const regulationTableData = apiResponse.map((each) => ({
            ...each,
            id: each.regId,
          }));
          dispatch(setAllRegulationList({ value: regulationTableData }));
        }
      } else {
        setSpinner(false);
        setRegulationApiError(true);
      }
    };
    RegulationApi.getRegulations(config, apiCallback);
  };

  const getAvailableRegulationCount = () => {
    setSpinner(true);
    const headers = getApiHeaders(authxToken.access_token);
    const config = {
      ...headers,
    };
    const apiCallback = (response) => {
      if (response.status === 200) {
        if (
          response
          && response.data
          && response.data.pageableFields
        ) {
          const availableRegulationCount = response.data.pageableFields.totalEstimates;
          getRegulationsApiData(availableRegulationCount);
        }
      } else {
        setSpinner(false);
        setRegulationApiError(true);
      }
    };
    RegulationApi.getRegulations(config, apiCallback);
  };

  const toggleDrawer = (drawerState, selectedId) => {
    if (drawerState) {
      handleAlertClose();
      setIsDrawerOpen(true);
      setSelectedRegId(selectedId);
      const activeRegulation = regulationsData.filter(
        (item) => item.regId === selectedId,
      );
      const formattedActiveRegulation = getFormattedActiveRegulation(
        activeRegulation[0],
      );
      const regulatedParts = formattedActiveRegulation.parts.map((each) => ({
        ...each,
        obsoleteDate: each.obsoleteDate ? getFormattedDate(each.obsoleteDate) : each.obsoleteDate,
        id: `${each.partNo}-${each.partDesc}-${each.lineAbbrev}`,
      }));

      const nonExpiryartParts = [];
      regulatedParts?.forEach((rp) => {
        const currentDate = getTodayDate();
        if (rp?.expiredDate === null || rp?.expiredDate > currentDate) {
          nonExpiryartParts.push(rp);
        }
      });

      setSelectedRegulation(formattedActiveRegulation);
      setSelectedRegulationFilter(nonExpiryartParts);
    } else {
      setSelectedRegId(null);
      setIsDrawerOpen(false);
      setSelectedRegulation({});
      setSelectedRegulationFilter({});
      setPartsSearchString("");
    }
  };

  const updateExisitingRegulationList = (updatedData) => {
    const updatedList = [...regulationList];
    if (updatedData && updatedData.regId) {
      const updatedRegIndex = regulationList.findIndex((each) => each.regId === updatedData.regId);
      const updatedRegData = { id: regulationList[updatedRegIndex].id, ...updatedData };
      updatedList[updatedRegIndex] = updatedRegData;
      dispatch(setAllRegulationList({ value: updatedList }));
    }
    setSelectedTab("regTabOne");
    setSpinner(false);
  };

  const addRegulationToExisitingList = (newData) => {
    let updatedList = [];
    if (regulationList && regulationList.length > 0) {
      updatedList = [{ ...newData, id: newData.regId }, ...regulationList];
    } else {
      updatedList = [{ ...newData, id: newData.regId }];
    }
    dispatch(setAllRegulationList({ value: updatedList }));
    setSelectedTab("regTabOne");
    setSpinner(false);
  };

  useEffect(() => {
    if (updatedRegulationData && updatedRegulationData.regId) {
      setSpinner(true);
      setShowApiSuccess(true);
      const regulationToUpdate = regulationsData.filter(
        (item) => item.regId === selectedRegulationIds,
      );
      // eslint-disable-next-line no-unsafe-optional-chaining
      setApiSuccessMessage(regulationToUpdate[0]?.regTitle
      + REGULATIONS_CONSTANTS.UPDATE_API_SUCCESS);
      updateExisitingRegulationList(updatedRegulationData);
      dispatch(setUpdatedRegulationData({ value: {} }));
    }
  }, [updatedRegulationData]);

  useEffect(() => {
    if (newRegulationData && newRegulationData.regId) {
      setSpinner(true);
      setShowApiSuccess(true);
      // eslint-disable-next-line no-unsafe-optional-chaining
      setApiSuccessMessage(newRegulationData?.regTitle + REGULATIONS_CONSTANTS.SAVE_API_SUCCESS);
      addRegulationToExisitingList(newRegulationData);
    }
  }, [newRegulationData]);

  useEffect(() => {
    const nonExipriryPartsdata = [];
    if (regulationList?.length > 0) {
      regulationList?.forEach((rl) => {
        const nonExipiryObj = { ...rl };
        const nonExpiryartParts = [];
        nonExipiryObj?.parts?.forEach((rp) => {
          const currentDate = getTodayDate();
          if (rp?.expiredDate === null || rp?.expiredDate > currentDate) {
            nonExpiryartParts.push(rp);
          }
        });
        if (nonExpiryartParts.length >= 0) {
          nonExipiryObj.parts = nonExpiryartParts;
        }
        nonExipriryPartsdata.push(nonExipiryObj);
      });
      setRegulationsData(regulationList);
      setFilteredRegulationsData(nonExipriryPartsdata);
      const stateOptions = getAllStateCodes(regulationList);
      setRegStateOptions(stateOptions);
    }
  }, [regulationList]);

  useEffect(() => {
    if (selectedRegulation && selectedRegulation.id) {
      setIsDetailsLoaded(true);
    } else {
      setIsDetailsLoaded(false);
    }
  }, [selectedRegulation]);

  useEffect(() => {
    getAvailableRegulationCount();
  }, []);

  const { REGULATION_HEADER_LABELS } = REGULATIONS_CONSTANTS;

  const columns = [
    {
      field: REGULATIONS_CONSTANTS.REG_TITLE,
      headerName: REGULATION_HEADER_LABELS[0],
      sortable: true,
      width: 400,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component="button"
          onClick={() => {
            toggleDrawer(true, params.row.id);
          }}
        >
          {params.row.regTitle}
        </Link>
      ),
    },
    {
      field: REGULATIONS_CONSTANTS.RULE_NO,
      headerName: REGULATION_HEADER_LABELS[1],
      sortable: true,
      width: 200,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_JURISDICTION_LEVEL,
      headerName: REGULATION_HEADER_LABELS[2],
      sortable: true,
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.STATE_CODE,
      headerName: REGULATION_HEADER_LABELS[3],
      sortable: true,
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.REG_TYPE,
      headerName: REGULATION_HEADER_LABELS[4],
      sortable: true,
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.EFF_DATE,
      headerName: REGULATION_HEADER_LABELS[5],
      sortable: true,
      width: 120,
    },
    {
      field: REGULATIONS_CONSTANTS.PARTS,
      headerName: REGULATION_HEADER_LABELS[6],
      sortable: true,
      width: 120,
      valueGetter: (params) => `${params.row.parts.length}`,
    },
    {
      headerName: REGULATION_HEADER_LABELS[7],
      width: 120,
      renderCell: (params) => (userRoles.includes("Block_Sale_RW") ? (
        <EditIcon onClick={() => { handleEditRegulation(params.row.id); }} />
      ) : <EditOffIcon />),
    },
  ];

  const { REGULATION_DETAILS_HEADER_LABELS, REGULATION_PARTS_LABELS } = REGULATIONS_CONSTANTS;
  const partsTableColumns = useMemo(() => [
    {
      field: REGULATIONS_CONSTANTS.PART_ABBR,
      headerName: REGULATION_PARTS_LABELS[0],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_NO,
      headerName: REGULATION_PARTS_LABELS[1],
      width: 130,
    },
    {
      field: REGULATIONS_CONSTANTS.PART_DESC,
      headerName: REGULATION_PARTS_LABELS[2],
      width: 250,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_LINE_ABBR,
      headerName: REGULATION_PARTS_LABELS[4],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_PART_NO,
      headerName: REGULATION_PARTS_LABELS[5],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.ALT_PART_DESC,
      headerName: REGULATION_PARTS_LABELS[6],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_DATE,
      headerName: REGULATION_PARTS_LABELS[7],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.CREATED_BY,
      headerName: REGULATION_PARTS_LABELS[8],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_DATE,
      headerName: REGULATION_PARTS_LABELS[9],
      width: 150,
    },
    {
      field: REGULATIONS_CONSTANTS.MODIFIED_BY,
      headerName: REGULATION_PARTS_LABELS[10],
      width: 150,
    },
  ], []);

  const regulationAdditionalDetails = [
    {
      fieldValue: REGULATIONS_CONSTANTS.REG_DESC,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[0],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.EFF_DATE,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[1],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.STATE_CODE,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[2],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.REG_COUNTY,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[3],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.REG_CITY,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[4],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.OTHER,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[5],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.CREATED_DATE,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[6],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.CREATED_BY,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[7],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.MODIFIED_DATE,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[8],
    },
    {
      fieldValue: REGULATIONS_CONSTANTS.MODIFIED_BY,
      fieldName: REGULATION_DETAILS_HEADER_LABELS[9],
    },
  ];

  return (
    <div>
      <Header />
      <div className="regulation-container">
        <Box sx={{ width: "100%" }}>
          <Tabs
            sx={{ color: "#001489", fontWeight: 700, fontSize: "21px" }}
            value={selectedTab}
            onChange={handleTabChange}
            textColor="#001489"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              value="regTabOne"
              icon={<RuleIcon />}
              iconPosition="start"
              label={REGULATIONS_CONSTANTS.VIEW_REGULATIONS}
            />
            {(!userRoles.includes("Block_Sale_RW")) ? ""
              : (
                <Tab
                  value="regTabTwo"
                  icon={<AddTaskOutlinedIcon />}
                  iconPosition="start"
                  label={REGULATIONS_CONSTANTS.ADD_REGULATION}
                  disabled={!userRoles.includes("Block_Sale_RW")}
                />
              )}
          </Tabs>
        </Box>
        {showApiSuccess && (
          <Alert
            severity="success"
            variant="outlined"
            sx={{ padding: "25px", margin: "20px" }}
            onClose={handleAlertClose}
          >
            {apiSuccessMessage}
          </Alert>
        )}
        {selectedTab === "regTabOne" && (
          <div className="view-regulations-container">
            {spinner && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!regulationApiError && !spinner && (
              <div>
                <div className="regulation-list-filters">
                  <div className="filter-container">
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <FormControl
                        sx={{ m: 1, width: 400, backgroundColor: "#fff" }}
                      >
                        <TextField
                          id="reg-search"
                          type="search"
                          variant="outlined"
                          value={searchString}
                          onChange={handleSearchChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <FormControl
                        sx={{ width: 500, backgroundColor: "#fff" }}
                      >
                        <TextField
                          id="reg-search"
                          type="search"
                          variant="outlined"
                          value={fieldLinePartsSearchString}
                          onChange={handleSearchFiledLineParts}
                          label={REGULATIONS_CONSTANTS.SEARCH_FIELDLINE_PART_PLACEHOLDER}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <FormControl
                        sx={{ m: 1, width: 150, backgroundColor: "#fff" }}
                      >
                        <InputLabel id="reg-state-option-label">
                          {GENERIC_CONSTANTS.STATE}
                        </InputLabel>
                        <Select
                          labelId="regulation-state"
                          id="reg-state-option"
                          value={selectedState}
                          label="State"
                          onChange={handleStateSelection}
                        >
                          {regStateOptions
                            && regStateOptions.length > 0
                            && regStateOptions.map((eachState) => (
                              <MenuItem
                                key={`regState-${eachState}`}
                                value={eachState}
                              >
                                {eachState}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </div>
                </div>
                <div className="regulation-table">
                  {filteredRegulationsData
                    && filteredRegulationsData.length > 0 && (
                      <Box sx={{ height: 400, width: "100%" }}>
                        <DataGrid
                          rows={filteredRegulationsData}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                            sorting: {
                              sortModel: [
                                { field: "createdDate", sort: "desc" },
                              ],
                            },
                          }}
                          pageSizeOptions={[10]}
                          disableRowSelectionOnClick
                          disableColumnSelector
                          disableColumnMenu
                          autoHeight
                          classes={{ withBorderColor: "#001489" }}
                        />
                      </Box>
                  )}
                  {filteredRegulationsData
                    && filteredRegulationsData.length === 0 && (
                      <Alert severity="info">
                        {REGULATIONS_CONSTANTS.NO_SEARCH_RESULTS}
                      </Alert>
                  )}
                  <Drawer
                    sx={{ width: "50% !important" }}
                    anchor="right"
                    open={isDrawerOpen}
                    className="partDetailsDrawer"
                    onClose={() => toggleDrawer(false)}
                  >
                    {!isDetailsLoaded && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "50%",
                        }}
                      >
                        <CircularProgress size={100} disableShrink />
                      </Box>
                    )}
                    {isDetailsLoaded && (
                      <div>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <CloseIcon onClick={() => toggleDrawer(false)} />
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <span className="regPartsTitle">
                            {selectedRegulation.regTitle}
                          </span>
                        </Grid>
                        <div className="parts-filter-section">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            <span className="regPartsTitle">
                              {REGULATIONS_CONSTANTS.DETAILS_TITLE}
                            </span>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          />
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <FormControl
                              sx={{ width: 500, backgroundColor: "#fff" }}
                            >
                              <TextField
                                id="reg-search"
                                type="search"
                                variant="outlined"
                                value={partsSearchString}
                                onChange={handleSearchParts}
                                label={REGULATIONS_CONSTANTS.SEARCH_PLACEHOLDER}
                              />
                            </FormControl>
                          </Grid>
                        </div>
                        <div className="uploaded-parts-table">
                          {selectedRegulationFilter
                          && selectedRegulationFilter.length > 0 && (
                          <DataGrid
                            rows={selectedRegulationFilter}
                            columns={partsTableColumns}
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  pageSize: 10,
                                },
                              },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            disableColumnSelector
                            disableColumnMenu
                            autoHeight
                            classes={{ withBorderColor: "#001489" }}
                          />
                          )}
                        </div>
                        {partsSearchString
                         && selectedRegulationFilter
                         && selectedRegulationFilter.length === 0
                          && (
                            <Alert severity="info">
                              {REGULATIONS_CONSTANTS.NO_SEARCH_PARTS_RESULTS}
                            </Alert>
                          )}
                        {!partsSearchString
                          && selectedRegulationFilter
                          && selectedRegulationFilter.length === 0
                          && (
                            <Alert severity="info">
                              {REGULATIONS_CONSTANTS.NO_PARTS_AVAILABLE}
                            </Alert>
                          )}
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <span className="regDetailsTitle">
                            {REGULATIONS_CONSTANTS.ADDITIONAL_DETAILS_TITLE}
                          </span>
                        </Grid>
                        <List component="nav" aria-label="mailbox folders">
                          {regulationAdditionalDetails.map((each) => (
                            <ListItem key={each.fieldName}>
                              <ListItemText
                                sx={{ width: "50%", fontWeight: "high" }}
                                primary={(
                                  <span>
                                    <b>{each.fieldName}</b>
                                  </span>
                                )}
                              />
                              <ListItemText
                                sx={{ width: "50%" }}
                                primary={(
                                  <span>
                                    {selectedRegulation[each.fieldValue]
                                      ? selectedRegulation[each.fieldValue]
                                      : "-"}
                                  </span>
                                )}
                              />
                              <Divider />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )}
                  </Drawer>
                </div>
              </div>
            )}
            {regulationApiError && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{ padding: "25px", margin: "20px" }}
              >
                {REGULATIONS_CONSTANTS.API_FAILURE}
              </Alert>
            )}
            {isUpdateRegulation && (
              <UpdateRegulations
                isOpen={isUpdateRegulation}
                onCloseEditModal={handleEditModalClose}
              />
            )}
          </div>
        )}
        {selectedTab === "regTabTwo" && userRoles.includes("Block_Sale_RW") && <AddRegulations />}
        {isDataLossAlert && (
          <div>
            <Dialog
              open={isDataLossAlert}
              onClose={() => handleDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
                {REGULATIONS_CONSTANTS.DATA_DISCARD_ALERT_TITLE}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" className="alert-dialog-description">
                  {REGULATIONS_CONSTANTS.DATA_DISCARD_ALERT_MSG}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="dialog-button-primary" variant="contained" onClick={() => handleDataDiscard(false)}>{GENERIC_CONSTANTS.DISCARD}</Button>
                <Button className="dialog-button-secondary" variant="contained" onClick={() => handleDialogOpen(false)} autoFocus>
                  {GENERIC_CONSTANTS.CANCEL}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewRegulations;
