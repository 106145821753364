import { createSlice } from "@reduxjs/toolkit";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";

export const initialState = {
  regulationParts: [],
  uploadPartsError: {},
  regulationFormData: {
    regId: null,
    regTitle: "",
    regDesc: "",
    effDate: "",
    regType: "Banned",
    ruleNo: "",
    jurisdictionLevel: "State",
    regDist: "",
    countryCode: "US",
    stateCode: "CA",
    county: null,
    city: null,
    other: null,
    zipCode: "",
    createdBy: "",
    parts: [],
  },
  editRegulationFormData: {},
  newRegulationData: {},
  updatedRegulationData: {},
  regulationList: [],
};

const DATA_FIELDS = ["lineAbbrev", "partNo", "partDesc", "bestClass",
  "obsoleteDate", "altLineAbbrev", "altPartNumber", "altPartDesc"];
const HEADER_LINE = "[\"TAMS Line Abbrv\",\"Part Number\",\"Part Description\","
    + "\"Best Class\",\"Obsolete Date\",\"Alt. TAMS Line Abbrv\",\"Alt. Part Number\",\"Alt. Part Description\"]";

const covertToPartList = (data) => {
  let partList = [];
  let errMsg = "";
  try {
    data.forEach((v0) => {
    // consider the line is empty/comment if the Line abbreviation is missing
    // validate required fields here: #0 line abbrv and #1 part number is required
      if (v0 && v0.length > 0) {
        if (v0[0] && v0[1]) {
          partList.push(
            v0.reduce((c, v, i) => (
              { ...c, [DATA_FIELDS[i]]: v }
            ), { [DATA_FIELDS[0]]: v0[0] || "" }),
          );
        } else {
          const error = !v0[0]
            ? REGULATIONS_CONSTANTS.MISSING_PART_LINE_ABBR
            : REGULATIONS_CONSTANTS.MISSING_PART_NO;
          throw new Error(error);
        }
      }
    });
  } catch (error) {
    errMsg = `${error.message} in the uploaded template! Please correct the file and upload again.`;
    partList = [];
  }
  return [partList, errMsg];
};

const regulationMaintenanceSlice = createSlice({
  name: "regulationMaintenanceForm",
  initialState,
  reducers: {
    clearRegulationMaintenanceForm: () => ({
      ...initialState,
    }),

    logError: (state, action) => ({
      ...state,
      uploadPartsError: {
        ...state.uploadPartsError,
        [action.payload.name]: action.payload.value,
      },
    }),

    setPartList: (state, action) => {
      const data = action.payload.value;
      let err;
      let partList = [];
      if (JSON.stringify(data[0]) !== HEADER_LINE) {
        err = REGULATIONS_CONSTANTS.WRONG_TEMP;
      } else {
        [partList, err] = covertToPartList(data.slice(1));
      }
      return {
        ...state,
        regulationParts: partList,
        uploadPartsError: {
          ...state.uploadPartsError,
          regulationParts: err,
        },
      };
    },
    saveRegulationFormData: (state, action) => ({
      ...state,
      regulationFormData: action.payload.value,
    }),
    updateRegulationFormData: (state, action) => ({
      ...state,
      editRegulationFormData: action.payload.value,
    }),
    clearRegulationFormData: (state) => ({
      ...state,
      regulationFormData: initialState.regulationFormData,
      editRegulationFormData: initialState.editRegulationFormData,
      regulationParts: initialState.regulationParts,
      uploadPartsError: initialState.uploadPartsError,
      updatedRegulationData: initialState.updatedRegulationData,
    }),
    setAllRegulationList: (state, action) => ({
      ...state,
      regulationList: action.payload.value,
    }),
    setNewRegulationData: (state, action) => ({
      ...state,
      newRegulationData: action.payload.value,
    }),
    setPartListToUpdate: (state, action) => ({
      ...state,
      regulationParts: action.payload.value,
    }),
    setUpdatedRegulationData: (state, action) => ({
      ...state,
      updatedRegulationData: action.payload.value,
    }),
  },
});

export const {
  saveRegulationFormData,
  updateRegulationFormData,
  setAllRegulationList,
  setPartList,
  setNewRegulationData,
  setPartListToUpdate,
  setUpdatedRegulationData,
  clearRegulationMaintenanceForm,
  clearRegulationFormData,
  logError,
} = regulationMaintenanceSlice.actions;

export default regulationMaintenanceSlice.reducer;
